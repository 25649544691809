import { render, staticRenderFns } from "./EpicentralNew.vue?vue&type=template&id=79be3d57&scoped=true&"
import script from "./EpicentralNew.vue?vue&type=script&lang=js&"
export * from "./EpicentralNew.vue?vue&type=script&lang=js&"
import style0 from "./EpicentralNew.vue?vue&type=style&index=0&id=79be3d57&prod&lang=scss&scoped=true&"
import style1 from "./EpicentralNew.vue?vue&type=style&index=1&id=79be3d57&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79be3d57",
  null
  
)

export default component.exports